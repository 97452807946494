import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { SpinnerService } from '../../../snipper/spinner.service';
import { documentservice } from '../../documents.sevice';
import { Table } from 'primeng/table';
import { OverlayPanel } from 'primeng/overlaypanel';




@Component({
  selector: 'app-employee-pay-variance',
  templateUrl: './employee-pay-variance.component.html',
  styleUrls: ['./employee-pay-variance.component.css']
})
export class EmployeePayVarianceComponent implements OnInit {

  ClientDropDown = [];
  CompanyDropDown = [];
  monthYearDropDown = [];
  monthYear: any;
  CompanyID: any;
  ClientID: any;
  overallMonthData = [];
  EmployeesData = [];
  headerList = [];
  HeaderList = [];
  searchHeaders = [];
  monthNames = [];
  chooseMonth: any;
  employeeID: any;
  chooseYear: any;
  showForm = false;
  user_type = localStorage.getItem('user_type');
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  activeTab = 'last_month vs current_month';
  EmployeeName = [];
  EmployeesDataCurrent = [];
  overallCurrentMonthData = [];
  overallPreviousMonthData = [];
  EmployeeCurrentData = [];
  EmployeeMonthprovisionData = [];
  overallCurrentMonth = [];
  overallMonthProvision = [];
  HeaderProvisionList = [];
  EmployeeNameData = [];
  currentMonthInputData = [];
  previousMonthInputData = [];
  popupEmployeeName: any;
  isFileShow = false;
  reportstatus: any;
  employee_name: any;
  component_name: any;
  component_key: any;
  employee_id: any;
  reportStatus: any;
  reportId: any;
  lastmonthCurrentmonth: number;
  EmployeeCount = [];
  reportColor= [];


  constructor(private Documentservice: documentservice, private apiModulesService: ApiModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService,) {
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.GetClientDropDown();
 
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  this.changeDropdown()
  }



  public GetClientDropDown() {
    this.apiModulesService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client != undefined ? data.data.client : [];

      this.ClientID = data.data.client[0].client_id;
      this.GetCompanyDropDown();

    }, err => {
      this.ClientDropDownReRender();
    })
  }

  /* company DropDown codes */
  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;

    this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.monthYearList();
        }, 100)
      }
      else {
        this.CompanyDropDownReRender();
      }

    }, error => {
      this.CompanyDropDownReRender();
    }
    )
  }

  /* company DropDown codes */
  public monthYearList() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;

    this.apiModulesService.list('admin/payInfo/dropDown', params).subscribe((data) => {
      this.monthYearDropDown = data.data.employee_pay_info != undefined ? data.data.employee_pay_info : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.monthYear = this.monthYearDropDown[0].payroll_period;
          this.changeDropdown()

        }, 100)
      }
      else {
        this.monthYearListReRender();
      }

    }, error => {
      this.monthYearListReRender();
    }
    )
  }


  changeDropdown() {
    if (this.activeTab === 'last_month vs current_month') {
      this.EmployeePayVarianceDetails();
    }
    if (this.activeTab === 'current_month vs month_provision') {
      this.EmployeeMonthProvisionDetails();
    }
  }

  EmployeePayVarianceDetails() {
    this.spinnerService.raiseDataEmitterEvent('on');

    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.month_year = this.monthYear;

    this.apiModulesService.list('admin/payvariance/list', params).subscribe((data) => {
      this.EmployeesData = data.data.pay_variance[0] != undefined ? data.data.pay_variance[0] : [];
      this.overallMonthData = data.data.pay_variance[1] != undefined ? data.data.pay_variance[1] : [];
      this.overallCurrentMonthData = data.data.pay_variance[2] != undefined ? data.data.pay_variance[2] : [];
      this.overallPreviousMonthData = data.data.pay_variance[3] != undefined ? data.data.pay_variance[3] : [];
      this.EmployeeCount = data.data.pay_variance[4] != undefined ? data.data.pay_variance[4] : [];
      this.reportColor = data.data.pay_variance[5] != undefined ? data.data.pay_variance[5]:[];
      this.searchHeaders = data.data.pay_variance[6][0].headerlist != undefined ? data.data.pay_variance[6][0].headerlist : [];
      this.searchHeaders.push('employee_name');
      this.headerList = this.searchHeaders.filter((datas: any) => datas !== 'employee_name');
      this.monthNames = data.data.pay_variance[7] != undefined ? data.data.pay_variance[7] : [];
      this.EmployeeName = data.data.pay_variance[8] != undefined ? data.data.pay_variance[8] : [];
    //  this.reportStatus = data.data.pay_variance[9][0].report_status != undefined ? data.data.pay_variance[9][0].report_status : [];
    //  this.reportId = data.data.pay_variance[9][0].reports_feedback_id != undefined ? data.data.pay_variance[9][0].reports_feedback_id : [];
      this.spinnerService.raiseDataEmitterEvent('off');

    }, error => {
      this.EmployeePayVarianceReRender();
    }
    )
  }


  EmployeeMonthProvisionDetails() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.month_year = this.monthYear;

    this.apiModulesService.list('admin/payvariance/monthprovisionlist', params).subscribe((data) => {
      this.EmployeeCurrentData = data.data.monthly_provision[0] != undefined ? data.data.monthly_provision[0] : [];
      this.EmployeeMonthprovisionData = data.data.monthly_provision[1] != undefined ? data.data.monthly_provision[1] : [];
      this.overallCurrentMonth = data.data.monthly_provision[2] != undefined ? data.data.monthly_provision[2] : [];
      this.overallMonthProvision = data.data.monthly_provision[3] != undefined ? data.data.monthly_provision[3] : [];
      this.searchHeaders = data.data.monthly_provision[4][0].headerlist != undefined ? data.data.monthly_provision[4][0].headerlist : [];
      this.searchHeaders.push('employee_name');
      this.HeaderProvisionList = this.searchHeaders.filter((datas: any) => datas !== 'employee_name');
      this.EmployeeNameData = data.data.monthly_provision[5] != undefined ? data.data.monthly_provision[5] : [];

      this.spinnerService.raiseDataEmitterEvent('off');

    }, error => {
      this.EmployeePayVarianceReRender();
    }
    )
  }

  EmployeeMonthInput(employee_id: any, employee_name: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.popupEmployeeName = employee_name;
    const params: any = {
      client_id: this.ClientID,
      company_id: this.CompanyID,
      month_year: this.monthYear,
      employee_id: employee_id,
    };
    // Pass the event object
    this.apiModulesService.list('admin/payvariance/monthinput', params).subscribe(
      (data) => {
        this.currentMonthInputData = data.data.monthly_input[0] || [];
        this.previousMonthInputData = data.data.monthly_input[1] || [];
        
        this.spinnerService.raiseDataEmitterEvent('off'); // Stop spinner
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinnerService.raiseDataEmitterEvent('off'); // Ensure spinner stops on error
      }
    );
  }







  EmployeeCurrentMonth(key: any, employee_id: any) {
    const filteredData = this.EmployeesData.filter((data) => data.employee_id === employee_id);

    if (filteredData.length > 0) {
      return filteredData[0][key] || 0;
    } else {
      return 0;
    }

  }

  EmployeePreviousMonth(key: any, employee_id: any) {
    const filterData = this.overallMonthData.filter((data) => data.employee_id === employee_id);

    if (filterData.length > 0) {

      return filterData[0][key] || 0;
    } else {
      return 0;
    }
  }

  EmployeesCurrentMonth(key: any, employee_id: any) {
    const filteredData = this.EmployeeCurrentData.filter((data) => data.employee_id === employee_id);

    if (filteredData.length > 0) {
      return filteredData[0][key] || 0;
    } else {
      return 0;
    }
  }

  EmployeeProvisionMonth(key: any, employee_id: any) {
    const filter = this.EmployeeMonthprovisionData.filter((data) => data.employee_id === employee_id);

    if (filter.length > 0) {
      return filter[0][key] || 0;
    } else {
      return 0;
    }
  }

  ReportColor(header: any, employee_id: any): boolean {
    const filter = this.reportColor.find(data => data.employee_id === employee_id);

    if (filter && filter[header] === "1") {
        return true;
    }
    return false;
}

  gettooltipText(header: any, employeeId: any){
    const currentMonthData:any = this.EmployeeCurrentMonth(header, employeeId);
    const previousMonthData:any = this.EmployeePreviousMonth(header, employeeId);
    return currentMonthData-previousMonthData
  }
  
  
  getTooltipText(provisionheader: any, employeeId: any){
    const currentMonthData:any = this.EmployeesCurrentMonth(provisionheader, employeeId);
    const provisionMonthData:any = this.EmployeeProvisionMonth(provisionheader, employeeId);
    return currentMonthData-provisionMonthData
  }




  EmployeePayVarianceReRender() {
    this.overallMonthData = [];
    this.EmployeesData = [];
    this.headerList = [];
    this.HeaderList = [];
    this.monthNames = [];
    this.spinnerService.raiseDataEmitterEvent('off');
  }

  monthYearListReRender() {
    this.monthYear = null;
    this.monthYearDropDown = [];
    this.EmployeePayVarianceReRender();
  }

  CompanyDropDownReRender() {
    this.CompanyID = null;
    this.CompanyDropDown = [];
    this.monthYearDropDown = [];
    this.monthYearListReRender();
  }

  ClientDropDownReRender() {
    this.ClientID = null;
    this.ClientDropDown = [];
    this.monthYearDropDown = [];
    this.CompanyDropDownReRender();
  }
  clear(table: Table) {
    table.clear();
  }

  getAcronym(str: any) {
    if (str != null) {
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches = matches.slice(0, 2);
      const acronym = matches.join(''); // JSON
      return acronym;
    }
  }

  getBgColors(str: any) {
    if (str != null) {
      const min = 0;
      const max = 15;
      const index = this.findAlphapositions(str, 1);
      return this.nameBgColors[index];
    }
  }
  findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

  // downloadExcel() {
  //   this.spinnerService.raiseDataEmitterEvent('on');
  //   const params: any = {};
  //   params.client_id = this.ClientID;
  //   params.company_id = this.CompanyID;
  //   params.month_year = this.monthYear;

  //   this.apiModulesService.list('admin/payInfo/download', params).subscribe((data) => {

  //     const fileDetails = data.data.employee_pay_info.file;
  //     this.Documentservice.DownloadExcel(fileDetails.name, fileDetails.excel)
  //     this.spinnerService.raiseDataEmitterEvent('off');
  //   }, error => {
  //     this.EmployeePayVarianceReRender();
  //   }
  //   )
  // }

  employeepayslipShow(employeeID) {
    const monthyear = this.monthYear.split('-');


    this.showForm = true;
    this.employeeID = employeeID;
    this.chooseMonth = monthyear[0];
    this.chooseYear = monthyear[1];
  }


  showEmployeeGrid(event) {
    this.showForm = event.value;
    this.changeDropdown();
  }

  showGridPage(event) {
    this.isFileShow = false;
  }

   //Edit paycomponent
   public onEditReportFeedback(employee_id: any, employee_name: any, component_name: any, component_key: any) {

    this.reportstatus = this.reportStatus;
    // Ensure you are using the correct variable
    if (this.reportstatus === 'close') {
        return;
    }
    
    this.employee_name = employee_name;
    this.component_name = component_name;
    this.component_key = component_key;
    this.employee_id = employee_id;
    this.isFileShow = true;
    
}


}
