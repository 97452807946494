// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal-info li {
  margin-bottom: 0px !important;
}
.card-formula{
  margin-bottom: 10px !important;
  box-shadow: 0px 4px 4px rgba(57, 92, 241, 0.19);
}
.pay-card-main
{
  margin-left:5px;
}
.pay-card-sub
{
  margin-left:40px;
  display: flex;
}
.title-label {
  display: inline-block; /* Ensures proper inline alignment */
  width: 50%; /* Adjust as needed */
  font-weight: bold;
  color: black;
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds "..." for overflowed text */
}

.title-value {
  display: inline-block; /* Aligns with the label */
  width: 60%; /* Adjust as needed */
  font-size: 13px;
  color: rgba(128, 128, 128, 0.82);
  overflow: hidden; /* Hides overflow text */
}

.bold-class{
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/ess-tabs/IND-ess/IND-employee-payroll/employee-payslip/payslip-formula-popup/payslip-formula-popup.component.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE,8BAA8B;EAC9B,+CAA+C;AACjD;AACA;;EAEE,eAAe;AACjB;AACA;;EAEE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,qBAAqB,EAAE,oCAAoC;EAC3D,UAAU,EAAE,qBAAqB;EACjC,iBAAiB;EACjB,YAAY;EACZ,mBAAmB,EAAE,sBAAsB;EAC3C,gBAAgB,EAAE,wBAAwB;EAC1C,uBAAuB,EAAE,mCAAmC;AAC9D;;AAEA;EACE,qBAAqB,EAAE,0BAA0B;EACjD,UAAU,EAAE,qBAAqB;EACjC,eAAe;EACf,gCAAgC;EAChC,gBAAgB,EAAE,wBAAwB;AAC5C;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".personal-info li {\r\n  margin-bottom: 0px !important;\r\n}\r\n.card-formula{\r\n  margin-bottom: 10px !important;\r\n  box-shadow: 0px 4px 4px rgba(57, 92, 241, 0.19);\r\n}\r\n.pay-card-main\r\n{\r\n  margin-left:5px;\r\n}\r\n.pay-card-sub\r\n{\r\n  margin-left:40px;\r\n  display: flex;\r\n}\r\n.title-label {\r\n  display: inline-block; /* Ensures proper inline alignment */\r\n  width: 50%; /* Adjust as needed */\r\n  font-weight: bold;\r\n  color: black;\r\n  white-space: nowrap; /* Prevents wrapping */\r\n  overflow: hidden; /* Hides overflow text */\r\n  text-overflow: ellipsis; /* Adds \"...\" for overflowed text */\r\n}\r\n\r\n.title-value {\r\n  display: inline-block; /* Aligns with the label */\r\n  width: 60%; /* Adjust as needed */\r\n  font-size: 13px;\r\n  color: rgba(128, 128, 128, 0.82);\r\n  overflow: hidden; /* Hides overflow text */\r\n}\r\n\r\n.bold-class{\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
