// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 76%;
  outline: 0;
  margin:auto;
}
.col-md-10{
  position: relative;
  margin-top: 30px;
  
}
.table-container {
  height: 60vh; /* Set a fixed height */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Optional: Hide horizontal scroll if not needed */
  border: 1px solid #ddd; /* Optional: Add a border for better visual separation */
  padding: 10px; /* Optional: Add padding around the container */
}

p-table {
  width: 100%; /* Ensure the table takes up the full width of the container */
}


.margin-right-zero{
  margin-right: 0px !important;
  margin-left: 0px !important;
}



`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/employee-pay-variance/employee-pay-variance.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,UAAU;EACV,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,gBAAgB;;AAElB;AACA;EACE,YAAY,EAAE,uBAAuB;EACrC,gBAAgB,EAAE,+BAA+B;EACjD,kBAAkB,EAAE,mDAAmD;EACvE,sBAAsB,EAAE,wDAAwD;EAChF,aAAa,EAAE,+CAA+C;AAChE;;AAEA;EACE,WAAW,EAAE,8DAA8D;AAC7E;;;AAGA;EACE,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":[".modal-content {\r\n  position: relative;\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 76%;\r\n  outline: 0;\r\n  margin:auto;\r\n}\r\n.col-md-10{\r\n  position: relative;\r\n  margin-top: 30px;\r\n  \r\n}\r\n.table-container {\r\n  height: 60vh; /* Set a fixed height */\r\n  overflow-y: auto; /* Enables vertical scrolling */\r\n  overflow-x: hidden; /* Optional: Hide horizontal scroll if not needed */\r\n  border: 1px solid #ddd; /* Optional: Add a border for better visual separation */\r\n  padding: 10px; /* Optional: Add padding around the container */\r\n}\r\n\r\np-table {\r\n  width: 100%; /* Ensure the table takes up the full width of the container */\r\n}\r\n\r\n\r\n.margin-right-zero{\r\n  margin-right: 0px !important;\r\n  margin-left: 0px !important;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
