import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-investment-defined-fields',
  templateUrl: './investment-defined-fields.component.html',
  styleUrls: ['./investment-defined-fields.component.css']
})
export class InvestmentDefinedFieldsComponent implements OnInit {
  dropdown: any;
  CountryDropDown: any;
  categoryNameDropdown: any;
  searchKeyword: string = '';
  FieldTypeDropdown: any;
  selectedCountryID: any;
  udOptions: string[] = [];
  YesorNoDropdown: any;
  fieldsdata = [];
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;
  addinvestmentFieldForm: FormGroup;
  dosDontsFieldForm: FormGroup;
  selectedCategoryId: any;
  categoryName: any;
  fieldOrder: any;
  keyDropdown: any;
  selectedKey: any;
  constructor(private spinnerService: SpinnerService,
    private apiModulesService: ApiModulesService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private fb: FormBuilder) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on')
    this.dropDown();

    this.addinvestmentFieldForm = this.fb.group({
      inv_ud_field_type: ['options'],
      inv_ud_field_option: ['']
    });
    this.addinvestmentFieldForm = this.formBuilder.group({
      inv_ud_field_key: ['', []],
      inv_dec_category_id: ['', []],
      inv_ud_field_label: ['', [Validators.required]],
      inv_ud_field_type: ['', [Validators.required]],
      inv_ud_field_option: ['', Validators.required],
      inv_ud_field_order: [{ value: '', disabled: true }, [Validators.required]],
      inv_ud_is_mandatory: ['', [Validators.required]],
      inv_ud_is_shown: ['', [Validators.required]],
      user_editable: ['', [Validators.required]],
      inv_defined_field_id: ['', []],
    })
    this.dosDontsFieldForm = this.formBuilder.group({
      dos: ['', [Validators.required]],
      donts: ['', [Validators.required]],
      defined_category_key: ['', [Validators.required]],
      inv_dec_category_id: ['', []]
    })
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
    this.addinvestmentFieldForm.get('inv_ud_field_type').valueChanges.subscribe(value => {
      const udOptionsControl = this.addinvestmentFieldForm.get('inv_ud_field_option');
      if (value === 'options' || value === 'checkbox'|| value==='radio') {
        udOptionsControl.setValidators([Validators.required]);
      } else {
        udOptionsControl.clearValidators();
      }
      udOptionsControl.updateValueAndValidity();
    });
    this.addinvestmentFieldForm.get('inv_ud_field_option').valueChanges.subscribe(value => {
      if (typeof value === 'string') {
        this.udOptions = value.split(/\s+/).filter(option => option.trim() !== '');
      } else {
        this.udOptions = value;
      }
    });

  }

  dropDown() {
    var params: any = {};
    this.apiModulesService.get('admin/inv-definedfield/dropdown').subscribe((data) => {
      this.dropdown = data.data.inv_defined;
      this.CountryDropDown = this.dropdown[0];
      this.FieldTypeDropdown = this.dropdown[1];
      this.YesorNoDropdown = this.dropdown[2];
      this.selectedCountryID = this.CountryDropDown[0].country_id;
      this.dropDownKey();
    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off')

    })
  }
  dropDownKey() {
    var params: any = {};
    this.apiModulesService.get('admin/inv-definedfield/dropdown-key').subscribe((data) => {
      this.keyDropdown = data.data.inv_key != undefined ? data.data.inv_key : [];
      this.selectedKey = this.keyDropdown[0].inv_dec_key;
      this.categoryDropDown();
    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off')

    })
  }
  categoryDropDown() {
    if (this.selectedKey == 'investment_declaration') {
      var params: any = {};
      params.country_id = this.selectedCountryID;
      this.apiModulesService.list('admin/inv-definedfield/category-name', params).subscribe((data) => {
        this.categoryName = data.data.inv_defined != undefined ? data.data.inv_defined : [];
        this.selectedCategoryId = this.categoryName[0].inv_dec_category_id;

        this.getList();
      }, (err) => {
        this.spinnerService.raiseDataEmitterEvent('off')

      })
    } else {
      this.categoryName = [];
      this.getList();

    }

  }
  public getList() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.country_id = this.selectedCountryID;
    params.inv_dec_category_key = this.selectedKey;
    params.inv_dec_category_id = this.selectedCategoryId;

    this.apiModulesService.list('admin/inv-definedfield/list', params).subscribe((data) => {
      if (data.data.inv_defined && data.data.inv_defined.length > 0) {
        this.fieldsdata = data.data.inv_defined;
        this.fieldOrder = data.data.inv_defined[0].next_field_order;
      } else {
        this.fieldsdata = [];
        this.fieldOrder = 1;
      }
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
      this.spinnerService.raiseDataEmitterEvent('off');
    }
    );
  }
  public onAddField(val: any) {
    if (!this.addinvestmentFieldForm.valid) {
      this.addinvestmentFieldForm.markAllAsTouched();
      return;
    }

    var params: any = {};
    params.inv_ud_field_key = this.addinvestmentFieldForm.get('inv_ud_field_key').value;
    params.inv_ud_field_label = this.addinvestmentFieldForm.get('inv_ud_field_label').value;
    params.inv_ud_field_type = this.addinvestmentFieldForm.get('inv_ud_field_type').value;
    params.inv_ud_field_option = this.addinvestmentFieldForm.get('inv_ud_field_option').value;
    params.inv_ud_field_order = this.fieldOrder;
    params.inv_ud_is_mandatory = this.addinvestmentFieldForm.get('inv_ud_is_mandatory').value;
    params.inv_ud_is_shown = this.addinvestmentFieldForm.get('inv_ud_is_shown').value;
    params.user_editable = this.addinvestmentFieldForm.get('user_editable').value;
    params.inv_dec_category_id = this.selectedCategoryId;
    params.inv_dec_category_key = this.selectedKey;
    params.inv_ud_field_option = params.inv_ud_field_option != null && params.inv_ud_field_option != '' ? params.inv_ud_field_option.toString() : null;
    if (this.addinvestmentFieldForm.get('inv_defined_field_id').value != '' && this.addinvestmentFieldForm.get('inv_defined_field_id').value != null) {
      params.inv_defined_field_id = this.addinvestmentFieldForm.get('inv_defined_field_id').value;
      this.apiModulesService.add(params, 'admin/inv-definedfield/update',).subscribe((data) => {
        this.getList();
        this.toastr.success('field Update sucessfully...!', 'Success');
      }, (err) => {
        this.toastr.error('Something went wrong!', 'Try Again');
      }
      );
    } else {
      this.apiModulesService.add(params, 'admin/inv-definedfield/add',).subscribe((data) => {
        this.getList();
        this.toastr.success('field added sucessfully...!', 'Success');
      }, (err) => {
        this.toastr.error('Something went wrong!', 'Try Again');
      }
      );
    }
    this.getList();
    ($('#add_field') as any).modal('hide');
    this.addinvestmentFieldForm.reset();
  }
  public onAddDosDonts() {
    if (!this.dosDontsFieldForm.valid) {
      this.dosDontsFieldForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.inv_dec_category_id = this.selectedCategoryId || null;
    params.defined_category_key = this.selectedKey;
    params.dos = this.dosDontsFieldForm.get('dos').value;
    params.donts = this.dosDontsFieldForm.get('donts').value;
    params.country_id = this.selectedCountryID;
    // params.inv_dos_dont_id = this.dosDontsFieldForm.get('dos_dont_id').value;
    this.apiModulesService.add(params, 'admin/inv-definedfield/update_dos_donts',).subscribe((data) => {
      this.getList();
      this.toastr.success('Field Updated sucessfully...!', 'Success');
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Try Again');
    }
    );
    this.getList();
    ($('#add_dos_donts') as any).modal('hide');
    this.dosDontsFieldForm.reset();
  }
  resetForm() {
    this.addinvestmentFieldForm.reset();
    this.dosDontsFieldForm.reset();
  }
  public update(fieldDetails: any) {
    this.addinvestmentFieldForm.setValue({
      inv_defined_field_id: fieldDetails.inv_defined_field_id,
      inv_dec_category_id: fieldDetails.inv_dec_category_id,
      inv_dec_category_key: this.selectedKey,
      inv_ud_field_label: fieldDetails.inv_ud_field_label,
      inv_ud_field_type: fieldDetails.inv_ud_field_type,
      inv_ud_field_key: fieldDetails.inv_ud_field_key,
      inv_ud_field_option: fieldDetails.inv_ud_field_option,
      inv_ud_field_order: fieldDetails.inv_ud_field_order,
      inv_ud_is_mandatory: fieldDetails.inv_ud_is_mandatory,
      inv_ud_is_shown: fieldDetails.inv_ud_is_shown,
      user_editable: fieldDetails.user_editable,
    });
  };
  onEditField(inv_defined_field_id: any) {
    const params: any = {};
    params.inv_defined_field_id = inv_defined_field_id
    this.apiModulesService.edit(params, 'admin/inv-definedfield/edit').subscribe((data) => {
      const fieldDetails = (data.data !== undefined) ? data.data.inv_defined : [];
      this.addinvestmentFieldForm.setValue({
        inv_defined_field_id: inv_defined_field_id,
        inv_dec_category_id: fieldDetails.inv_dec_category_id,
        inv_ud_field_label: fieldDetails.inv_ud_field_label,
        inv_ud_field_type: fieldDetails.inv_ud_field_type,
        inv_ud_field_key: fieldDetails.inv_ud_field_key,
        inv_ud_field_option: fieldDetails.inv_ud_field_option,
        inv_ud_field_order: fieldDetails.inv_ud_field_order,
        inv_ud_is_mandatory: fieldDetails.inv_ud_is_mandatory,
        inv_ud_is_shown: fieldDetails.inv_ud_is_shown,
        user_editable: fieldDetails.user_editable
      });
    });
  }
  onEditDosDonts() {
    const params: any = {};
    params.inv_dec_category_id = this.selectedCategoryId;
    params.defined_category_key= this.selectedKey;
    this.apiModulesService.edit(params, 'admin/inv-definedfield/edit_dos_donts').subscribe((data) => {
      const fieldDetails = (data.data !== undefined) ? data.data.dos_donts[0] : [];
      this.dosDontsFieldForm.setValue({
        inv_dec_category_id: this.selectedCategoryId,
        defined_category_key: this.selectedKey,
        dos: fieldDetails.dos,
        donts: fieldDetails.donts
      });
    });
  }
  changeStatus(Status: any, inv_defined_field_id: any) {
    const params: any = {};
    params.inv_defined_field_id = inv_defined_field_id;
    this.apiModulesService.edit(params, 'admin/inv-definedfield/' + Status).subscribe((data) => {
      this.getList();
      ($('#userdefinedfield-status') as any).modal('hide');
      this.toastr.success('Status Changed Successfully!', 'Success');
    })
  }
  clear(table: Table) {
    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';
      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;
    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
}
