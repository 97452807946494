// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 76%;
    outline: 0;
    margin:auto;
}
.col-md-10{
    position: relative;
    margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/report-feedback/report-feedback.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,UAAU;IACV,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".modal-content {\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 76%;\r\n    outline: 0;\r\n    margin:auto;\r\n}\r\n.col-md-10{\r\n    position: relative;\r\n    margin-top: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
