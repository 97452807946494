import { Component,OnInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from '../../../snipper/spinner.service';
import { Table } from 'primeng/table';
import { documentservice } from '../../documents.sevice';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-employee-ytd',
  templateUrl: './employee-ytd.component.html',
  styleUrls: ['./employee-ytd.component.css']
})
export class EmployeeYtdComponent {

  ClientDropDown = [];
      CompanyDropDown = [];
      monthYearDropDown = [];
      overallMonthData = [];
      EmployeesData = [];
      employeeData = [];
      searchHeaders = [];
      headerList = [];
      ClientID: any;
      CompanyID: any;
      chooseMonth: any;
      employeeID: any;
      chooseYear: any;
      monthYear: any;
      searchKeyword: any;
      showForm = false;
      user_type = localStorage.getItem('user_type');
      nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
      nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
      activeTab = 'employee_ytd';
      EmployeesRecurringData = [];
      OverallMonthData = [];
      editReportFeedbackForm: FormGroup
  
      HeaderList = [];
      EmployeesNonRecurringData = [];
      OverallNonMonthData = [];
      HeaderNonrecurringList = [];
      HeaderEmployeeDataList = [];
      ComponentID: any;
      employee_name: any;
      Component = [];
      component_name: any;
      componentDisplayName: string = '';
      reportStatus: any;
      reportstatus: any;
      component_id: any;
      reportId: any;
      EmployeeCount = [];
      component_key: any;
      employee_id: any;
      slap_type: string;
      recurringEmployeeCount = [];
      NonRecurringEmployeeCount: any;
      isFileShow = false;
      ComponentDropDown: any;
      isFileshow = false;
      reportColor = [];
  
  
      constructor(private Documentservice: documentservice, private apiModulesService: ApiModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService,private cdr: ChangeDetectorRef) {
      }
  
      ngOnInit() {
          this.spinnerService.raiseDataEmitterEvent('on');
          this.GetClientDropDown();
  
          this.editReportFeedbackForm = this.formBuilder.group({
              remarks: ['', Validators.required],
          });
      }
  
  
  
      // setActiveTab(tab: string) {
      //     this.activeTab = tab;
      //     if (tab === 'all') {
      //         this.EmployeePayInfoDetails();
      //     }
      //     if (tab === 'recurring') {
      //         this.EmployeeRecurringDetails();
      //     }
      //     if (tab === 'non_recurring') {
      //         this.EmployeeNonRecurringDetails();
      //     }
      //     if (tab === 'employee_data') {
      //         this.EmployeeData();
      //     }
      // }
  
      // chageDropdown() {
      //     if (this.activeTab === 'all') {
      //         this.EmployeePayInfoDetails();
      //     }
      //     if (this.activeTab === 'recurring') {
      //         this.EmployeeRecurringDetails();
      //     }
      //     if (this.activeTab === 'non_recurring') {
      //         this.EmployeeNonRecurringDetails();
      //     }
      //     if (this.activeTab === 'employee_data') {
      //         this.EmployeeData();
      //     }
      // }
  
      public GetClientDropDown() {
          this.apiModulesService.get('DropDown/client').subscribe((data) => {
              this.ClientDropDown = data.data.client != undefined ? data.data.client : [];
  
              this.ClientID = data.data.client[0].client_id;
              this.GetCompanyDropDown();
  
          }, err => {
              this.ClientDropDownReRender();
          })
      }
  
      /* company DropDown codes */
      public GetCompanyDropDown() {
          const params: any = {};
          params.client_id = this.ClientID;
  
          this.apiModulesService.list('DropDown/company', params).subscribe((data) => {
              this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
              if (this.CompanyDropDown.length !== 0) {
                  setTimeout(() => {
                      this.CompanyID = this.CompanyDropDown[0].company_id;
                      this.monthYearList();
                  }, 100)
              }
              else {
                  this.CompanyDropDownReRender();
              }
  
          }, error => {
              this.CompanyDropDownReRender();
          }
          )
      }
  
      /* company DropDown codes */
      public monthYearList() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
    
        this.apiModulesService.list('admin/payInfo/dropDown', params).subscribe((data) => {
          this.monthYearDropDown = data.data.employee_pay_info != undefined ? data.data.employee_pay_info : [];
          if (this.CompanyDropDown.length !== 0) {
            setTimeout(() => {
              this.monthYear = this.monthYearDropDown[0].payroll_period;
              this.EmployeeYtdDetails()
    
            }, 100)
          }
          else {
            this.monthYearListReRender();
          }
    
        }, error => {
          this.monthYearListReRender();
        }
        )
      }
  
      // public GetComponentDropDown() {
      //     this.apiModulesService.get('DropDown/component-name').subscribe((data) => {
      //         this.ComponentDropDown = data.data.componentname != undefined ? data.data.componentname : [];
  
      //         this.ComponentID = data.data.componentname[1].component_id;
              
  
      //     }, err => {
              
      //     })
      // }
  
      EmployeeYtdDetails() {
          this.spinnerService.raiseDataEmitterEvent('on');
          const params: any = {};
          params.client_id = this.ClientID;
          params.company_id = this.CompanyID;
          params.month_year = this.monthYear;
  
          this.apiModulesService.list('admin/employeeytd/list', params).subscribe((data) => {
              this.EmployeesData = data.data.employee_ytd[0] != undefined ? data.data.employee_ytd[0] : [];
              this.overallMonthData = data.data.employee_ytd[1] != undefined ? data.data.employee_ytd[1] : [];
              this.EmployeeCount = data.data.employee_ytd[2] != undefined ? data.data.employee_ytd[2] : [];
              this.reportColor = data.data.employee_ytd[3] != undefined ? data.data.employee_ytd[3]:[];
              this.searchHeaders = data.data.employee_ytd[4][0].header_list != undefined ? data.data.employee_ytd[4][0].header_list : [];
              this.searchHeaders.push('employee_name');
              this.headerList = this.searchHeaders.filter((datas: any) => datas !== 'employee_name');
              //this.Component = data.data.employee_pay_info[4] != undefined ? data.data.employee_pay_info[4] : [];
              this.reportStatus = data.data.employee_ytd[5][0].report_status != undefined ? data.data.employee_ytd[5][0].report_status : [];
              this.reportId = data.data.employee_ytd[5][0].reports_feedback_id != undefined ? data.data.employee_ytd[5][0].reports_feedback_id : [];
              this.spinnerService.raiseDataEmitterEvent('off');
  
          }, error => {
              this.EmployeePayInfoReRender();
          }
          )
      };
  
      
      
      // gettooltipText(header: any, employeeId: any){
      //     const currentMonthData:any = this.ReportColor(header, employeeId);
         
      //     return currentMonthData
      //   }
      
      
  
     
  
      updatePayInfoDetails() {
          this.spinnerService.raiseDataEmitterEvent('on');
          const params: any = {};
          params.client_id = this.ClientID;
          params.company_id = this.CompanyID;
          params.month_year = this.monthYear;
          this.apiModulesService.list('admin/payInfo/update', params).subscribe((data) => {
              this.toaster.success('Updated Status Sucessfully...!', 'Success');
              ($('#pay_run_status') as any).modal('hide');
              this.spinnerService.raiseDataEmitterEvent('off');
  
          }, error => {
              this.EmployeePayInfoReRender();
          }
          )
      }
  
      EmployeePayInfoReRender() {
          this.overallMonthData = [];
          this.EmployeesData = [];
          this.headerList = [];
          this.HeaderList = [];
          this.spinnerService.raiseDataEmitterEvent('off');
      }
  
      monthYearListReRender() {
          this.monthYear = null;
          this.monthYearDropDown = [];
          this.EmployeePayInfoReRender();
      }
  
      CompanyDropDownReRender() {
          this.CompanyID = null;
          this.CompanyDropDown = [];
          this.monthYearDropDown = [];
          this.monthYearListReRender();
      }
  
      ClientDropDownReRender() {
          this.ClientID = null;
          this.ClientDropDown = [];
          this.monthYearDropDown = [];
          this.CompanyDropDownReRender();
      }
      clear(table: Table) {
          table.clear();
      }
  
      getAcronym(str: any) {
          if (str != null) {
              let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
              matches = matches.slice(0, 2);
              const acronym = matches.join(''); // JSON
              return acronym;
          }
      }
  
      getBgColors(str: any) {
          if (str != null) {
              const min = 0;
              const max = 15;
              const index = this.findAlphapositions(str, 1);
              return this.nameBgColors[index];
          }
      }
      findAlphapositions(str: any, n: any) {
          const NUM = 31;
  
          return str[0].charCodeAt(0) & NUM;
      }
  
      downloadExcel() {
          this.spinnerService.raiseDataEmitterEvent('on');
          const params: any = {};
          params.client_id = this.ClientID;
          params.company_id = this.CompanyID;
          params.month_year = this.monthYear;
  
          this.apiModulesService.list('admin/payInfo/download', params).subscribe((data) => {
  
              const fileDetails = data.data.employee_pay_info.file;
              this.Documentservice.DownloadExcel(fileDetails.name, fileDetails.excel)
              this.spinnerService.raiseDataEmitterEvent('off');
          }, error => {
              this.EmployeePayInfoReRender();
          }
          )
      }
      employeepayslipShow(employeeID) {
          const monthyear = this.monthYear.split('-');
  
  
          this.showForm = true;
          this.employeeID = employeeID;
          this.chooseMonth = monthyear[0];
          this.chooseYear = monthyear[1];
      }
  
      showEmployeeGrid(event) {
          this.showForm = event.value;
          this.isFileShow = true;
          //this.setActiveTab(this.activeTab);        
      }
  
      showGridPage(event) {
          console.log('showGridPage triggered with event:', event);
          this.isFileShow = false;
      }
  
      //Edit paycomponent
      public onEditReportFeedback(employee_id: any, employee_name: any, component_name: any, component_key: any) {
  
          this.reportstatus = this.reportStatus;
          // Ensure you are using the correct variable
          if (this.reportstatus === 'close') {
              console.log("Report status is 'close', exiting.");
              return;
          }
          
          this.employee_name = employee_name;
          this.component_name = component_name;
          this.component_key = component_key;
          this.employee_id = employee_id;
          this.isFileShow = true;
          
      }

}
