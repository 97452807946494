import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { jsPDF } from 'jspdf';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from "../../../../../snipper/spinner.service";
import { PayslipFormulaPopupComponent } from './payslip-formula-popup/payslip-formula-popup.component';

@Component({
  selector: 'app-employee-payslip-download',
  templateUrl: './employee-payslip-download.component.html',
  styleUrls: ['./employee-payslip.component.css']
})
export class EmployeePayslipDownloadComponent implements OnInit {
  showTableEarnings = false;
  showTableDeduction = false;
  showNumber = true;
  showDate = true;
  showPanNumber = true;
  showUanNumber = true;
  showAccountNumber = true;
  showPfNumber = true;
  showEsiNumber = true;
  showTableNumber = false;
  showTable = false;
  download = false;
  employeeData = [];
  payslipData = [];
  payslipAddressData = [];
  dropDownValue = [];
  dropDownValueFilter = [];
  earningsData = [];
  deductionData = [];
  grossSalaryData = [];
  grossDeductionData = [];
  netSalaryData = [];
  @Input() chooseMonth: any;
  @Input() chooseYear: any;
  @Input() clientID: any;
  @Input() companyID: any;
  @Input() employeeID: any;
  @Output() backPage: EventEmitter<any> = new EventEmitter();
  annualGross: any;
  monthGross: any;
  estimatedTax:any;
  parentBack = false;
  awsLoginImages: any;
  inputNumber: number;
  result = '';
  noOfDaysInMonth: string;
  noOfDaysPaidFor: string;
  Downloads = false;
  clickedPopupData: any;

  @ViewChild(PayslipFormulaPopupComponent) payslipFormula: PayslipFormulaPopupComponent;

  constructor(private formBuilder: FormBuilder, private apiModulesService: ApiModulesService, private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.paySlipGrid();

  }

  beforeDownloadFunction() {
    this.spinnerService.raiseDataEmitterEvent('on');
    // this.showNumber = false;
    // this.showDate = false;
    // this.showPanNumber = false;
    // this.showUanNumber = false;
    // this.showAccountNumber = false;
    // this.showPfNumber = false;
    // this.showEsiNumber = false;
    // this.showTableNumber = true;
    // this.showTable = true;
    // this.showTableEarnings = true;
    // this.showTableDeduction = true;
    // this.download = true;
    this.Downloads = true;
    setTimeout(() => {
      this.Convert_HTML_To_PDF();
    }, 100);
  }

  print() {
    this.beforeDownloadFunction();
    setTimeout(() => {
      this.printContent();
    }, 2000);
  }

  downloads() {
    this.beforeDownloadFunction();
  }

  afterDownloadFunction() {
    this.showNumber = true;
    this.showDate = true;
    this.Downloads = false;
    this.showPanNumber = true;
    this.showUanNumber = true;
    this.showAccountNumber = true;
    this.showPfNumber = true;
    this.showEsiNumber = true;
    this.showTableNumber = false;
    this.showTable = false;
    this.showTableEarnings = false;
    this.showTableDeduction = false;
    this.download = false;
    this.spinnerService.raiseDataEmitterEvent('off');

  }

  // Convert HTML content to PDF
  Convert_HTML_To_PDF() {
    const doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    const elementHTML: any = document.querySelector('#contentToPrint');
    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        // doc.addPage('1','p')
        for (let i = doc.getNumberOfPages(); i != 1; i--) {
          doc.deletePage(i);
        }

        doc.setLineHeightFactor(10);
        doc.setFontSize(20);
        doc.setLineHeightFactor(10);
        doc.save('payslip.pdf');
      },
      margin: [10, 10, 10, 10],
      autoPaging: 'slice',
      x: 0,
      y: 10,
      width: 190, // target width in the PDF document
      windowWidth: 1100 // window width in CSS pixels

    });
    this.afterDownloadFunction();
  }

  printContent() {
    const printContents = document.getElementById('contentToPrint').innerHTML;
    const originalContents = document.body.innerHTML;
    // Replace the entire content of the page with the content you want to print
    document.body.innerHTML = printContents;
    // Trigger the print dialog
    window.print();
    // Restore the original content after printing
    document.body.innerHTML = originalContents;
    this.afterDownloadFunction();
  }

  paySlipGrid() {
    this.afterDownloadFunction();

    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.month_name = this.chooseMonth;
    params.year_no = this.chooseYear;

    this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/payinfo/list', params).subscribe((data) => {

      this.employeeData = data.data.pay_slip[0] != undefined ? data.data.pay_slip[0] : [];
      this.payslipData = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
      this.payslipAddressData = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
      this.awsLoginImages = this.payslipAddressData[0].profile_img_url != null ? 'data:image/png;base64,' + this.payslipAddressData[0].profile_img_url : null;
      this.annualGross = data.data.pay_slip[0][0].annual_gross != undefined ? data.data.pay_slip[0][0].annual_gross : 0;
      this.monthGross = data.data.pay_slip[0][0].monthly_gross != undefined ? data.data.pay_slip[0][0].monthly_gross : 0;
      this.estimatedTax = data.data.pay_slip[0][0].estimated_annual_taxable_income != undefined ? data.data.pay_slip[0][0].estimated_annual_taxable_income : 0;
      if (this.payslipData.length !== 0) {
        this.Filters('earningsData', 'payslipData', 'component_type', 'Earnings');
        this.Filters('deductionData', 'payslipData', 'component_type', 'Deductions');
        this.Filters('grossSalaryData', 'payslipData', 'component_key', 'Gross_Earnings');
        this.Filters('grossDeductionData', 'payslipData', 'component_key', 'Gross_Deduction');
        this.Filters('netSalaryData', 'payslipData', 'component_key', 'Net_Salary');
        this.result = this.numberToWords(this.netSalaryData[0].component_values != undefined ? this.netSalaryData[0].component_values : 0);
        this.noOfDaysInMonth = this.numberToWords(this.employeeData[0].no_of_days != undefined ? this.employeeData[0].no_of_days : 0);
        this.noOfDaysPaidFor = this.numberToWords(this.employeeData[0].no_of_days_paid != undefined ? this.employeeData[0].no_of_days_paid : 0);

      } else {
        this.earningsData = [];
        this.deductionData = [];
        this.grossSalaryData = [];
        this.grossDeductionData = [];
        this.netSalaryData = [];
      }
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err: any) => {
      this.earningsData = [];
      this.deductionData = [];
      this.grossSalaryData = [];
      this.grossDeductionData = [];
      this.netSalaryData = [];
      this.spinnerService.toasterError(err);
    })

  }

  Filters(filteredArray, mainArray, keyFilter, filterBasedValue) {
    this[filteredArray] = this[mainArray].filter((val: any) => val[keyFilter] === filterBasedValue);
  }

  numberToWords(number: number): string {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
 
    if (number === 0) {
        return 'Zero';
    }
 
    let result = '';
 
    if (number < 10) {
        result = ones[number];
    } else if (number < 20) {
        result = teens[number - 10]; // Fixed: Including "Ten"
    } else if (number < 100) {
        result = tens[Math.floor(number / 10)] + (number % 10 !== 0 ? ' ' + ones[number % 10] : '');
    } else if (number < 1000) {
        result = ones[Math.floor(number / 100)] + ' Hundred' + (number % 100 !== 0 ? ' ' + this.numberToWords(number % 100) : '');
    } else if (number < 100000) {
        result = this.numberToWords(Math.floor(number / 1000)) + ' Thousand' + (number % 1000 !== 0 ? ' ' + this.numberToWords(number % 1000) : '');
    } else if (number < 10000000) {
        result = this.numberToWords(Math.floor(number / 100000)) + ' Lakh' + (number % 100000 !== 0 ? ' ' + this.numberToWords(number % 100000) : '');
    } else if (number < 1000000000) {
        result = this.numberToWords(Math.floor(number / 10000000)) + ' Crore' + (number % 10000000 !== 0 ? ' ' + this.numberToWords(number % 10000000) : '');
    } else {
        result = 'Number out of range';
    }
 
    return result.trim();
}

  back() {
    this.backPage.emit(this.parentBack);

  }

  openFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      if (data.component_key == 'Income_Tax') {
        this.payslipFormula.incomePopUp();
        this.payslipFormula.payslipFormula();
      } else {
        this.payslipFormula.openPopUp();

      }
    }, 100)
  }
  openYtdFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      this.payslipFormula.openYTDPopUp();
    }, 100)
  }
  singleFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      this.payslipFormula.singleCalculationPopUp();
    }, 100)
  }

}

