import { Component, NgModule, OnInit, } from '@angular/core';
import Chart from 'chart.js/auto';
import { ChartOptions } from 'chart.js';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-pfcontribution',
  templateUrl: './pfcontribution.component.html',
  styleUrls: ['./pfcontribution.component.css']
})
@NgModule({
  imports: [FormsModule],

})
export class PfcontributionComponent implements OnInit {
  initialized = false;
  fromYearSelcted: any;
  toYearSelcted: any;
  pfcontributionyear: any = [];
  pfcontributioncareer: any = [];
  pfContributionYearPieChart: any;
  public ShowForm: any = false;
  public ShowYearGraph: any = true;
  public ShowCareerGraph: any = true;
  isLegendCollapsed = false;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  pfGridChart: any;
  PfBarChart: any;
  yearPfPieChart: any;
  contWiseBarChart: any;
  contWisePieChart: any;
  chartTitleBar: any;
  PieChartTitle: any;
  vpfBarChart: any;
  vpfPieChart: any;
  erPfGridChart: any;
  careerBarChart: any;
  careerPieChart: any;
  careerWiseBarChart: any;
  careerChartBarTitle: any;
  careerPieChartTitle: any;
  careerVpfBarChart: any;
  careerVpfPieChart: any;
  pfContributionCareerPieChart: { ee_pf: number; er_pf: number; eps: number; vpf: number; };
  careerWisePieChart: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  choosemonth_year: any;
  chooseYear: any;
  year = [];
  dropDownValue: any;
  dropDownValueFilter: any;
  financialYearId: any;
  yearDropDown: any = [];
  initialized_career = false;
  initialized_year = false;
  chartLabels: any;
  toyearDropDown: any;
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService) {
  }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');

    this.dropDown();
  }
  YearDataApi() {
    if (this.chooseYear == null || this.chooseYear == '') {
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.financial_id = this.chooseYear;
    let promise = new Promise((resolve, reject) => {
      this.apiModulesService.list('employee-tabs/IND-employee-payroll/pf-contribution/list', params).subscribe((data) => {
        this.spinnerService.raiseDataEmitterEvent('off');
        resolve(data)

      }, (err: any) => {
        reject('no data')
        this.spinnerService.toasterError(err);
      });
    })
    let data = promise.then((result: any) => {
      this.pfcontributionyear = result.data.pf_contribution != undefined ? result.data.pf_contribution : [];
      this.pfContributionYearPieChart = { ee_pf: 0, er_pf: 0, eps: 0, vpf: 0 };
      for (let i = 0; i < this.pfcontributionyear.length; i++) {
        this.pfContributionYearPieChart.ee_pf = Number(this.pfContributionYearPieChart?.ee_pf != undefined ? this.pfContributionYearPieChart.ee_pf : 0) + Number(this.pfcontributionyear[i].ee_pf)
        this.pfContributionYearPieChart.er_pf = Number(this.pfContributionYearPieChart?.er_pf != undefined ? this.pfContributionYearPieChart.er_pf : 0) + Number(this.pfcontributionyear[i].er_pf)
        this.pfContributionYearPieChart.eps = Number(this.pfContributionYearPieChart?.eps != undefined ? this.pfContributionYearPieChart.eps : 0) + Number(this.pfcontributionyear[i].eps)
        this.pfContributionYearPieChart.vpf = Number(this.pfContributionYearPieChart?.vpf != undefined ? this.pfContributionYearPieChart.vpf : 0) + Number(this.pfcontributionyear[i].vpf)
      }
      return result
    });
    return data;
  }

  getPfContributionData() {
    let promise = new Promise((resolve, reject) => {
      let data = this.YearDataApi();
      resolve(data);
      reject('no data')

    });
    let value = promise.then((result) => {

      if (this.initialized_year == false) {
        this.pfContributionGridGraph();
        this.initialized_year = true;
      } else {
        this.contributionYearBarGraph();
        this.contributionYearPieGraph();
        this.contributionWiseBarGraph();
        this.contributionWisePieGraph();
        this.contributionChartTitleGraph();
        this.chartTitlePieGraph();
        this.contributionYearVpfBarGraph();
        this.contributionVpfPieGraph();


      }

      return result
    }).catch((reason) => {
      return reason;
    });
    return value



  }
  updateYearGraph() {
    let promise = new Promise((resolve, reject) => {
      let data = this.YearDataApi();
      resolve(data);
      reject('no data')

    });
    let value = promise.then((result) => {


      if (this.initialized_year == true && this.ShowForm == true && this.ShowYearGraph == false) {

        setTimeout(() => {
          this.yearPfPieChart.data.datasets[0].data = [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf, this.pfContributionYearPieChart.eps, this.pfContributionYearPieChart.vpf];
          this.yearPfPieChart.update();
          this.contWisePieChart.data.datasets[0].data = [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf, this.pfContributionYearPieChart.eps];
          this.contWisePieChart.update();
          this.PieChartTitle.data.datasets[0].data = [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf];
          this.PieChartTitle.update();
          this.vpfPieChart.data.datasets[0].data = [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf, this.pfContributionYearPieChart.vpf];
          this.vpfPieChart.update();
          this.PfBarChart.data.labels = this.pfcontributionyear.map((data: any) => data.month_year);
          this.PfBarChart.data.datasets[0].data = this.pfcontributionyear.map((data: any) => data.ee_pf);
          this.PfBarChart.data.datasets[1].data = this.pfcontributionyear.map((data: any) => data.er_pf);
          this.PfBarChart.data.datasets[2].data = this.pfcontributionyear.map((data: any) => data.eps);
          this.PfBarChart.data.datasets[3].data = this.pfcontributionyear.map((data: any) => data.vpf);
          this.PfBarChart.update();
          this.contWiseBarChart.data.labels = this.pfcontributionyear.map((data: any) => data.month_year);
          this.contWiseBarChart.data.datasets[0].data = this.pfcontributionyear.map((data: any) => data.ee_pf);
          this.contWiseBarChart.data.datasets[1].data = this.pfcontributionyear.map((data: any) => data.er_pf);
          this.contWiseBarChart.data.datasets[2].data = this.pfcontributionyear.map((data: any) => data.eps);
          this.contWiseBarChart.update();
          this.chartTitleBar.data.labels = this.pfcontributionyear.map((data: any) => data.month_year);
          this.chartTitleBar.data.datasets[0].data = this.pfcontributionyear.map((data: any) => data.ee_pf);
          this.chartTitleBar.data.datasets[1].data = this.pfcontributionyear.map((data: any) => data.er_pf);
          this.chartTitleBar.update();
          this.vpfBarChart.data.labels = this.pfcontributionyear.map((data: any) => data.month_year);
          this.vpfBarChart.data.datasets[0].data = this.pfcontributionyear.map((data: any) => data.ee_pf);
          this.vpfBarChart.data.datasets[1].data = this.pfcontributionyear.map((data: any) => data.er_pf);
          this.vpfBarChart.data.datasets[2].data = this.pfcontributionyear.map((data: any) => data.vpf);
          this.vpfBarChart.update();
        }, 1000)

      } else if (this.initialized_career == true && this.ShowForm == false && this.ShowYearGraph == true) {
        setTimeout(() => {
          this.pfGridChart.data.labels = this.pfcontributionyear.map((data: any) => data.month_year);
          this.pfGridChart.data.datasets[0].data = this.pfcontributionyear.map((data: any) => data.ee_pf);
          this.pfGridChart.data.datasets[1].data = this.pfcontributionyear.map((data: any) => data.er_pf);
          this.pfGridChart.data.datasets[2].data = this.pfcontributionyear.map((data: any) => data.eps);
          this.pfGridChart.data.datasets[3].data = this.pfcontributionyear.map((data: any) => data.vpf);
          this.pfGridChart.update();
        }, 1000)
      }

      return result
    }).catch((reason) => {
      return reason;
    });
    return value

  }
  careearDataApi() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.from_finance_date = this.fromYearSelcted;
    params.to_finance_date = this.toYearSelcted;
    let promise = new Promise((resolve, reject) => {
      this.apiModulesService.list('employee-tabs/IND-employee-payroll/pf-contribution/list-year', params).subscribe((data) => {
        this.spinnerService.raiseDataEmitterEvent('off');
        resolve(data)

      }, (err: any) => {
        reject('no data')
        this.spinnerService.toasterError(err);
      });
    })
    let data = promise.then((result: any) => {
      this.pfcontributioncareer = result.data.pf_contribution != undefined ? result.data.pf_contribution : [];
      this.pfContributionCareerPieChart = { ee_pf: 0, er_pf: 0, eps: 0, vpf: 0 };
      for (let i = 0; i < this.pfcontributioncareer.length; i++) {
        this.pfContributionCareerPieChart.ee_pf = Number(this.pfContributionCareerPieChart?.ee_pf != undefined ? this.pfContributionCareerPieChart.ee_pf : 0) + Number(this.pfcontributioncareer[i].ee_pf)
        this.pfContributionCareerPieChart.er_pf = Number(this.pfContributionCareerPieChart?.er_pf != undefined ? this.pfContributionCareerPieChart.er_pf : 0) + Number(this.pfcontributioncareer[i].er_pf)
        this.pfContributionCareerPieChart.eps = Number(this.pfContributionCareerPieChart?.eps != undefined ? this.pfContributionCareerPieChart.eps : 0) + Number(this.pfcontributioncareer[i].eps)
        this.pfContributionCareerPieChart.vpf = Number(this.pfContributionCareerPieChart?.vpf != undefined ? this.pfContributionCareerPieChart.vpf : 0) + Number(this.pfcontributioncareer[i].vpf)
      }
      return result
    });
    return data;
  }

  getPfContributionYearData() {
    let promise = new Promise((resolve, reject) => {
      let data = this.careearDataApi();
      resolve(data);
      reject('no data')

    });
    let value = promise.then((result) => {

      if (this.initialized_career == false) {
        this.careerPfGridGraph();

        this.initialized_career = true;
      } else {
        this.CareerBarGraph();
        this.CareerPieGraph();
        this.careerWiseBarGraph();
        this.careerWisePieGraph();
        this.careerChartTitleBarGraph();
        this.careerChartTitlePieGraph();
        this.careerVpfBarGraph();
        this.careerVpfPieGraph();


      }

      return result
    }).catch((reason) => {
      return reason;
    });
    return value



  }
  updateCarrearGraph() {
    let promise = new Promise((resolve, reject) => {
      let data = this.careearDataApi();
      resolve(data);
      reject('no data')

    });
    let value = promise.then((result) => {


      if (this.initialized_career == true && this.ShowForm == true && this.ShowCareerGraph == false) {

        setTimeout(() => {
          this.careerVpfPieChart.data.datasets[0].data = [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf, this.pfContributionCareerPieChart.vpf];
          this.careerVpfPieChart.update();
          this.careerPieChartTitle.data.datasets[0].data = [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf];
          this.careerPieChartTitle.update();
          this.careerPieChart.data.datasets[0].data = [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf, this.pfContributionCareerPieChart.eps, this.pfContributionCareerPieChart.vpf];
          this.careerPieChart.update();
          this.careerWisePieChart.data.datasets[0].data = [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf, this.pfContributionCareerPieChart.eps];
          this.careerWisePieChart.update();

          this.careerBarChart.data.labels = this.pfcontributioncareer.map((data: any) => data.month_year);
          this.careerBarChart.data.datasets[0].data = this.pfcontributioncareer.map((data: any) => data.ee_pf);
          this.careerBarChart.data.datasets[1].data = this.pfcontributioncareer.map((data: any) => data.er_pf);
          this.careerBarChart.data.datasets[2].data = this.pfcontributioncareer.map((data: any) => data.eps);
          this.careerBarChart.data.datasets[3].data = this.pfcontributioncareer.map((data: any) => data.vpf);
          this.careerBarChart.update();
          this.careerVpfBarChart.data.labels = this.pfcontributioncareer.map((data: any) => data.month_year);
          this.careerVpfBarChart.data.datasets[0].data = this.pfcontributioncareer.map((data: any) => data.ee_pf);
          this.careerVpfBarChart.data.datasets[1].data = this.pfcontributioncareer.map((data: any) => data.er_pf);
          this.careerVpfBarChart.data.datasets[2].data = this.pfcontributioncareer.map((data: any) => data.vpf);
          this.careerVpfBarChart.update();
          this.careerWiseBarChart.data.labels = this.pfcontributioncareer.map((data: any) => data.month_year);
          this.careerWiseBarChart.data.datasets[0].data = this.pfcontributioncareer.map((data: any) => data.ee_pf);
          this.careerWiseBarChart.data.datasets[1].data = this.pfcontributioncareer.map((data: any) => data.er_pf);
          this.careerWiseBarChart.data.datasets[2].data = this.pfcontributioncareer.map((data: any) => data.eps);
          this.careerWiseBarChart.update();
          this.careerChartBarTitle.data.labels = this.pfcontributioncareer.map((data: any) => data.month_year);
          this.careerChartBarTitle.data.datasets[0].data = this.pfcontributioncareer.map((data: any) => data.ee_pf);
          this.careerChartBarTitle.data.datasets[1].data = this.pfcontributioncareer.map((data: any) => data.er_pf);
          this.careerChartBarTitle.update();
        }, 1000)

      } else if (this.initialized_career == true && this.ShowForm == false && this.ShowCareerGraph == true) {
        setTimeout(() => {
          this.erPfGridChart.data.labels = this.pfcontributioncareer.map((data: any) => data.month_year);
          this.erPfGridChart.data.datasets[0].data = this.pfcontributioncareer.map((data: any) => data.ee_pf);
          this.erPfGridChart.data.datasets[1].data = this.pfcontributioncareer.map((data: any) => data.er_pf);
          this.erPfGridChart.data.datasets[2].data = this.pfcontributioncareer.map((data: any) => data.eps);
          this.erPfGridChart.data.datasets[3].data = this.pfcontributioncareer.map((data: any) => data.vpf);
          this.erPfGridChart.update();

        }, 1000)
      }

      return result
    }).catch((reason) => {
      return reason;
    });
    return value

  }


  dropDown() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/drop-down', params).subscribe((data) => {
      this.dropDownValue = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
      this.year = data.data.pay_slip[0] != undefined ? data.data.pay_slip[0] : [];
      this.chooseYear = data.data.pay_slip[2][0].company_financial_year_id;
      this.dropDownValueFilter = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
      this.yearDropDown = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
      this.toyearDropDown = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
      // this.financialYearId = this.yearDropDown[0].company_financial_year_id != undefined ? this.yearDropDown[0].company_financial_year_id : null
      this.fromYearSelcted = this.yearDropDown[0].start_date
      this.toYearSelectChange()
      this.getPfContributionYearData()
      this.getPfContributionData();
    },(err)=>{
      this.spinnerService.toasterError(err);

    })
  }

  toYearSelectChange(){
    this.toyearDropDown=this.yearDropDown.filter((data:any)=>new Date (data.start_date)>=new Date (this.fromYearSelcted))
    this.toYearSelcted = this.toyearDropDown[0].start_date
    this.updateCarrearGraph();

  }
  // chartDropDown() {
  //   const params: any = {};
  //   params.client_id = this.clientID;
  //   params.company_id = this.companyID;
  //   params.employee_id = this.employeeID;

  //   this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/drop-down', params).subscribe((data) => {
  //     this.dropDownValue = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
  //     this.year = data.data.pay_slip[0] != undefined ? data.data.pay_slip[0] : [];
  //     this.chooseYear = this.chooseYear;
  //     this.dropDownValueFilter = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
  //     this.yearDropDown = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
  //     this.fromYearSelcted = this.fromYearSelcted
  //     this.toYearSelcted = this.toYearSelcted
  //     this.getPfContributionYearData()
  //     this.getPfContributionData();


  //   })

  // }

  // Year Graph start code

  pfContributionGridGraph() {

    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          stacked: true,
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    // this.chartLabels.pfChart;
    // this.chartLabels.pfChart= this.pfcontributionyear.map((data: any) => data.month_year)

    this.pfGridChart = new Chart("PFContributionChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributionyear.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributionyear.map((data: any) => data.ee_pf),
          },
          {
            label: 'ERPF',
            data: this.pfcontributionyear.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },
          {
            label: 'EREPS',
            data: this.pfcontributionyear.map((data: any) => data.eps),
            backgroundColor: '#c29fc7'
          },
          {
            label: 'EEVPF',
            data: this.pfcontributionyear.map((data: any) => data.vpf),
            backgroundColor: '#e3c6a1'
          }
        ]
      },
      options: chartOptions
    });

    $("#pfChartclick").click(() => {
      let anyHidden = this.pfGridChart.data.datasets.some(ds => ds.hidden);

      this.pfGridChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.pfGridChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.pfGridChart = new Chart("PFContributionChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributionyear.map((data: any) => data.month_year),
          datasets: this.pfGridChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.pfGridChart.update();
    });


  }
  // ChartHide(chartName,chartId){

  // }
  contributionYearBarGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          stacked: true,
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.PfBarChart = new Chart("EmployeerContributionChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributionyear.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributionyear.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributionyear.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },
          {

            label: 'EREPS',
            data: this.pfcontributionyear.map((data: any) => data.eps),
            backgroundColor: '#c29fc7'
          },
          {

            label: 'EEVPF',
            data: this.pfcontributionyear.map((data: any) => data.vpf),
            backgroundColor: '#e3c6a1'
          }
        ]
      },
      options: chartOptions
    });
    $("#PfBarChartclick").click(() => {
      let anyHidden = this.PfBarChart.data.datasets.some(ds => ds.hidden);

      this.PfBarChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.PfBarChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.PfBarChart = new Chart("EmployeerContributionChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributionyear.map((data: any) => data.month_year),
          datasets: this.PfBarChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.PfBarChart.update();
    });


  }
  contributionYearPieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.pfContributionYearPieChart = { ee_pf: 0, er_pf: 0, eps: 0, vpf: 0 };
    for (let i = 0; i < this.pfcontributionyear.length; i++) {
      this.pfContributionYearPieChart.ee_pf = Number(this.pfContributionYearPieChart?.ee_pf != undefined ? this.pfContributionYearPieChart.ee_pf : 0) + Number(this.pfcontributionyear[i].ee_pf)
      this.pfContributionYearPieChart.er_pf = Number(this.pfContributionYearPieChart?.er_pf != undefined ? this.pfContributionYearPieChart.er_pf : 0) + Number(this.pfcontributionyear[i].er_pf)
      this.pfContributionYearPieChart.eps = Number(this.pfContributionYearPieChart?.eps != undefined ? this.pfContributionYearPieChart.eps : 0) + Number(this.pfcontributionyear[i].eps)
      this.pfContributionYearPieChart.vpf = Number(this.pfContributionYearPieChart?.vpf != undefined ? this.pfContributionYearPieChart.vpf : 0) + Number(this.pfcontributionyear[i].vpf)
    }
    this.yearPfPieChart = new Chart("EmployeerYearContributionChart", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF', 'EREPS', 'EEVPF'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4', '#c29fc7', '#e3c6a1'],
            data: [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf, this.pfContributionYearPieChart.eps, this.pfContributionYearPieChart.vpf]
          },
        ]
      },
      options: chartOption
    });
    $("#yearpfChartclick").click(() => {
      let anyHidden = this.yearPfPieChart.data.datasets.some(ds => ds.hidden);

      this.yearPfPieChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.yearPfPieChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.yearPfPieChart = new Chart("EmployeerYearContributionChart", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF', 'EREPS', 'EEVPF'],
          datasets: this.yearPfPieChart.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.yearPfPieChart.update();
    });


  }
  contributionWiseBarGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {

          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.contWiseBarChart = new Chart("ContributionWiseChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributionyear.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributionyear.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributionyear.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },
          {

            label: 'EREPS',
            data: this.pfcontributionyear.map((data: any) => data.eps),
            backgroundColor: '#c29fc7'
          },

        ]
      },
      options: chartOptions
    });
    $("#contWiseChartclick").click(() => {
      let anyHidden = this.contWiseBarChart.data.datasets.some(ds => ds.hidden);

      this.contWiseBarChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.contWiseBarChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.contWiseBarChart = new Chart("ContributionWiseChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributionyear.map((data: any) => data.month_year),
          datasets: this.contWiseBarChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.contWiseBarChart.update();
    });

  }
  contributionWisePieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.contWisePieChart = new Chart("ContributionPieChart", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF', 'EREPS'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4', '#c29fc7'],
            data: [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf, this.pfContributionYearPieChart.eps]
          },
        ]
      },
      options: chartOption
    });
    $("#contPieChartclick").click(() => {
      let anyHidden = this.contWisePieChart.data.datasets.some(ds => ds.hidden);

      this.contWisePieChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.contWisePieChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.contWisePieChart = new Chart("ContributionPieChart", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF', 'EREPS'],
          datasets: this.contWisePieChart.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.contWisePieChart.update();
    });

  }
  contributionChartTitleGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          stacked: true,
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.chartTitleBar = new Chart("TitleChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributionyear.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributionyear.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributionyear.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },

        ]
      },
      options: chartOptions
    });
    $("#chartTitleclick").click(() => {
      let anyHidden = this.chartTitleBar.data.datasets.some(ds => ds.hidden);

      this.chartTitleBar.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.chartTitleBar.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.chartTitleBar = new Chart("TitleChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributionyear.map((data: any) => data.month_year),
          datasets: this.chartTitleBar.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.chartTitleBar.update();
    });

  }
  chartTitlePieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.PieChartTitle = new Chart("PieChartTitle", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4'],
            data: [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf]
          },
        ]
      },
      options: chartOption
    });
    $("#PieChartTitleclick").click(() => {
      let anyHidden = this.PieChartTitle.data.datasets.some(ds => ds.hidden);

      this.PieChartTitle.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.PieChartTitle.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.PieChartTitle = new Chart("PieChartTitle", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF'],
          datasets: this.PieChartTitle.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.PieChartTitle.update();
    });
  }
  contributionYearVpfBarGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {

          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.vpfBarChart = new Chart("VPFChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributionyear.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributionyear.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributionyear.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },
          {

            label: 'EEVPF',
            data: this.pfcontributionyear.map((data: any) => data.vpf),
            backgroundColor: '#e3c6a1'
          }
        ]
      },
      options: chartOptions
    });
    $("#VpfChartclick").click(() => {
      let anyHidden = this.vpfBarChart.data.datasets.some(ds => ds.hidden);

      this.vpfBarChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.vpfBarChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.vpfBarChart = new Chart("VPFChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributionyear.map((data: any) => data.month_year),
          datasets: this.vpfBarChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.vpfBarChart.update();
    });

  }
  contributionVpfPieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.vpfPieChart = new Chart("VpfPieChart", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF', 'EEVPF'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4', '#e3c6a1'],
            data: [this.pfContributionYearPieChart.ee_pf, this.pfContributionYearPieChart.er_pf, this.pfContributionYearPieChart.vpf]
          },
        ]
      },
      options: chartOption
    });
    $("#VpfPieChartclick").click(() => {
      let anyHidden = this.vpfPieChart.data.datasets.some(ds => ds.hidden);

      this.vpfPieChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.vpfPieChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.vpfPieChart = new Chart("VpfPieChart", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF', 'EEVPF'],
          datasets: this.vpfPieChart.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.vpfPieChart.update();
    });

  }
  // Year Graph End Code

  // Career Graph Start Code


  careerPfGridGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          stacked: true,
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.erPfGridChart = new Chart("PFEmployeerContributionChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributioncareer.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributioncareer.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributioncareer.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },
          {

            label: 'EREPS',
            data: this.pfcontributioncareer.map((data: any) => data.eps),
            backgroundColor: '#c29fc7'
          },
          {

            label: 'EEVPF',
            data: this.pfcontributioncareer.map((data: any) => data.vpf),
            backgroundColor: '#e3c6a1'
          }
        ]
      },
      options: chartOptions
    });
    $("#erpfchartclick").click(() => {
      let anyHidden = this.erPfGridChart.data.datasets.some(ds => ds.hidden);

      this.erPfGridChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.erPfGridChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.erPfGridChart = new Chart("PFEmployeerContributionChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributioncareer.map((data: any) => data.month_year),
          datasets: this.erPfGridChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.erPfGridChart.update();
    });


  }
  CareerBarGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          stacked: true,
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.careerBarChart = new Chart("EmployeerCareerChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributioncareer.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributioncareer.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributioncareer.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },
          {

            label: 'EREPS',
            data: this.pfcontributioncareer.map((data: any) => data.eps),
            backgroundColor: '#c29fc7'
          },
          {

            label: 'EEVPF',
            data: this.pfcontributioncareer.map((data: any) => data.vpf),
            backgroundColor: '#e3c6a1'
          }
        ]
      },
      options: chartOptions
    });
    $("#careerChartclick").click(() => {
      let anyHidden = this.careerBarChart.data.datasets.some(ds => ds.hidden);

      this.careerBarChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerBarChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerBarChart = new Chart("EmployeerCareerChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributioncareer.map((data: any) => data.month_year),
          datasets: this.careerBarChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.careerBarChart.update();
    });

  }
  CareerPieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.careerPieChart = new Chart("CareerChart", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF', 'EREPS', 'EEVPF'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4', '#c29fc7', '#e3c6a1'],
            data: [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf, this.pfContributionCareerPieChart.eps, this.pfContributionCareerPieChart.vpf]
          },
        ]
      },
      options: chartOption
    });
    $("#careerPieChartclick").click(() => {
      let anyHidden = this.careerPieChart.data.datasets.some(ds => ds.hidden);

      this.careerPieChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerPieChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerPieChart = new Chart("CareerChart", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF', 'EREPS', 'EEVPF'],
          datasets: this.careerPieChart.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.careerPieChart.update();
    });
  }
  careerWiseBarGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {

          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.careerWiseBarChart = new Chart("CareerWiseChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributioncareer.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributioncareer.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributioncareer.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },
          {

            label: 'EREPS',
            data: this.pfcontributioncareer.map((data: any) => data.eps),
            backgroundColor: '#c29fc7'
          },

        ]
      },
      options: chartOptions
    });
    $("#careerWiseChartclick").click(() => {
      let anyHidden = this.careerWiseBarChart.data.datasets.some(ds => ds.hidden);

      this.careerWiseBarChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerWiseBarChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerWiseBarChart = new Chart("CareerWiseChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributioncareer.map((data: any) => data.month_year),
          datasets: this.careerWiseBarChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.careerWiseBarChart.update();
    });


  }
  careerWisePieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.careerWisePieChart = new Chart("CareerWisePieChart", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF', 'EREPS'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4', '#c29fc7'],
            data: [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf, this.pfContributionCareerPieChart.eps]
          },
        ]
      },
      options: chartOption
    });
    $("#careerWisePieChartclick").click(() => {
      let anyHidden = this.careerWisePieChart.data.datasets.some(ds => ds.hidden);

      this.careerWisePieChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerWisePieChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerWisePieChart = new Chart("CareerWisePieChart", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF', 'EREPS'],
          datasets: this.careerWisePieChart.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.careerWisePieChart.update();
    });
  }
  careerChartTitleBarGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          stacked: true,
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.careerChartBarTitle = new Chart("CareerTitleChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributioncareer.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributioncareer.map((data: any) => data.ee_pf),
          },
          {

            label: 'ERPF',
            data: this.pfcontributioncareer.map((data: any) => data.er_pf),
            backgroundColor: '#b7ebe4'
          },

        ]
      },
      options: chartOptions
    });
    $("#careerChartTitleclick").click(() => {
      let anyHidden = this.careerChartBarTitle.data.datasets.some(ds => ds.hidden);

      this.careerChartBarTitle.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerChartBarTitle.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerChartBarTitle = new Chart("CareerTitleChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributioncareer.map((data: any) => data.month_year),
          datasets: this.careerChartBarTitle.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.careerChartBarTitle.update();
    });

  }
  careerChartTitlePieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.careerPieChartTitle = new Chart("CareerPieChartTitle", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4'],
            data: [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf]
          },
        ]
      },
      options: chartOption
    });
    $("#careerPieChartTitleclick").click(() => {
      let anyHidden = this.careerPieChartTitle.data.datasets.some(ds => ds.hidden);

      this.careerPieChartTitle.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerPieChartTitle.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerPieChartTitle = new Chart("CareerPieChartTitle", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF'],
          datasets: this.careerPieChartTitle.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.careerPieChartTitle.update();
    });
  }
  careerVpfBarGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {

          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
        y: {

          ticks: {
            font: {
              family: 'Open_Sans'
            }
          }
        },
      },
      aspectRatio: 2,

    };
    this.careerVpfBarChart = new Chart("CareerVPFChart", {
      type: 'bar',
      data: {
        labels: this.pfcontributioncareer.map((data: any) => data.month_year),
        datasets: [
          {
            label: 'EEPF',
            backgroundColor: '#adc0ed',
            data: this.pfcontributioncareer.map((data: any) => data.ee_pf)[0],
          },
          {

            label: 'ERPF',
            data: this.pfcontributioncareer.map((data: any) => data.er_pf)[0],
            backgroundColor: '#b7ebe4'
          },
          {

            label: 'EEVPF',
            data: this.pfcontributioncareer.map((data: any) => data.vpf)[0],
            backgroundColor: '#e3c6a1'
          }
        ]
      },
      options: chartOptions
    });
    $("#careerVpfChartclick").click(() => {
      let anyHidden = this.careerVpfBarChart.data.datasets.some(ds => ds.hidden);

      this.careerVpfBarChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerVpfBarChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerVpfBarChart = new Chart("CareerVPFChart", {
        type: 'bar',
        data: {
          labels: this.pfcontributioncareer.map((data: any) => data.month_year),
          datasets: this.careerVpfBarChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.careerVpfBarChart.update();
    });

  }
  careerVpfPieGraph() {
    const chartOption: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal',
              family: 'Open_Sans',
            },
          },
          position: 'bottom',
        },
      },
      aspectRatio: 2,

    };
    this.careerVpfPieChart = new Chart("CareerVpfPieChart", {
      type: 'pie',
      data: {
        labels: ['EEPF', 'ERPF', 'EEVPF'],
        datasets: [
          {
            backgroundColor: ['#adc0ed', '#b7ebe4', '#e3c6a1'],
            data: [this.pfContributionCareerPieChart.ee_pf, this.pfContributionCareerPieChart.er_pf, this.pfContributionCareerPieChart.vpf]
          },
        ]
      },
      options: chartOption
    });
    $("#careerVpfPieChartclick").click(() => {
      let anyHidden = this.careerVpfPieChart.data.datasets.some(ds => ds.hidden);

      this.careerVpfPieChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.careerVpfPieChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.careerVpfPieChart = new Chart("CareerVpfPieChart", {
        type: 'pie',
        data: {
          labels: ['EEPF', 'ERPF', 'EEVPF'],
          datasets: this.careerVpfPieChart.data.datasets // Reuse the datasets
        },
        options: chartOption
      });

      this.careerVpfPieChart.update();
    });
  }

  // Career Graph End Code

  pfYearGraph() {
    this.ShowForm = true;
    this.ShowYearGraph = false;
    setTimeout(() => { this.getPfContributionData() }, 1000
    )
  }
  goBack() {
    this.ShowForm = false;
    this.ShowYearGraph = true;
    this.ShowCareerGraph = true;
  }
  pfCareerGraph() {
    this.ShowForm = true;
    this.ShowCareerGraph = false;
    setTimeout(() => { this.getPfContributionYearData() }, 1000
    )
  }


  toggleLegend() {
    this.isLegendCollapsed = !this.isLegendCollapsed;
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;
    return str[0].charCodeAt(0) & NUM;
  }
  yearDropDownChange() {

  }

}
