import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { empty } from 'rxjs';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
    selector: 'app-payroll-run',
    templateUrl: './payroll-run.component.html',
    styleUrls: ['./payroll-run.component.css']
})
export class PayrollRunComponent {
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    userDropDown = [];
    userKey: any;
    PaygroupDropDown = [];
    PaygroupID: any;
    ClientDropDown = [];
    CompanyDropDown = [];
    ClientID: any
    CompanyID: any
    public ShowForm: any = false;
    payrollData: any;
    financialDropdownData: any;
    financial_month_year: any;
    payrollRunner: any;
    componentData: any;
    selectedItems: any[] = [];
    isRunButtonDisabled: boolean = true;
    selectedPayrolls: any[] = [];
    selectedFiltedPayrolls: any[] = [];

    constructor(private apiModuleService: ApiModulesService, private documentService: documentservice,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder, private spinnerService: SpinnerService) {
    }
    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.GetuserDropDown();
    }
    public GetuserDropDown() {
        this.apiModuleService.get('DropDown/user').subscribe((data) => {
            this.userDropDown = data.data.users;
            this.userKey = data.data.users[0].key;
            this.GetClientDropDown();
        },(err)=>{
            this.spinnerService.raiseDataEmitterEvent('off');

        })
    }

    /* client DropDown codes*/
    public GetClientDropDown() {
        this.apiModuleService.get('DropDown/client').subscribe((data) => {
            this.ClientDropDown = data.data.client;

            this.ClientID = data.data.client[0].client_id;
            if (this.ClientDropDown.length !== 0) {
                setTimeout(() => {
                    this.ClientID = data.data.client[0].client_id;
                    if (this.userKey == 'client') {
                        this.payDatesDropDown();
                    } else {
                        this.GetCompanyDropDown();
                    }
                }, 100)
            }




        },(err)=>{
            this.spinnerService.raiseDataEmitterEvent('off');

        })
    }
    public GetCompanyDropDown() {
        if (this.userKey != 'client') {
            const params: any = {};
            params.client_id = this.ClientID;
            this.apiModuleService.list('DropDown/company', params).subscribe((data) => {
                this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
                if (this.CompanyDropDown.length !== 0) {
                    setTimeout(() => {
                        this.CompanyID = this.CompanyDropDown[0].company_id;
                        if (this.userKey == 'company') {
                            this.payDatesDropDown();
                        } else {
                            this.GetpaygroupDropDown();
                        }

                    }, 100)
                }
                else {
                    this.CompanyID = null;
                }
            },(err)=>{
                this.spinnerService.raiseDataEmitterEvent('off');

            })
        } else {
            this.payDatesDropDown();
        }
    }

    /* client DropDown codes*/
    public GetpaygroupDropDown() {
        if (this.userKey == 'pay_group') {
            const params: any = {};
            params.client_id = this.ClientID;
            params.company_id = this.CompanyID;
            this.apiModuleService.list('DropDown/paygroups', params).subscribe((data) => {
                this.PaygroupDropDown = data.data.paygroup;
                this.PaygroupID = data.data.paygroup[0].pay_group_id;
                this.payDatesDropDown();
            },(err)=>{
                this.spinnerService.raiseDataEmitterEvent('off');

            })
        } else {
            this.payDatesDropDown();
        }
    }
    getPayRunDetails() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.user_key = this.userKey;
        params.company_id = this.CompanyID;
        params.pay_group_id = this.PaygroupID;
        params.financial_month_year = this.financial_month_year;
        this.apiModuleService.list("admin/payroll-admin/payroll/list", params).subscribe((data) => {
            this.payrollData = (data.data.payroll[1] != undefined) ? data.data.payroll[1] : [];
            this.componentData = (data.data.payroll[0] != undefined) ? data.data.payroll[0] : [];
            this.selectedPayrolls = [];
            this.selectedFiltedPayrolls = [];
            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err: any) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            this.payrollData = [];
            this.componentData = [];
            this.selectedPayrolls = [];
            this.selectedFiltedPayrolls = [];
            this.spinnerService.toasterError(err);
            // let errors = err.error.data.payroll;
            // errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            // this.toastrService.error(errors, 'TryAgain');
        });
    }


    public payDatesDropDown() {
        const params: any = {};
        params.client_id = this.ClientID;
        params.company_id = this.CompanyID;
        params.user_key = this.userKey;
        this.apiModuleService.list('admin/payroll-admin/payroll/dropdown', params).subscribe((data) => {
            this.financialDropdownData = data.data.payroll_report[0];
            this.financial_month_year = this.financialDropdownData?.[0]?.financial_month_year ?? null;
            this.payrollRunner =  data.data.payroll_report?.[1]?.[0]?.payroll_runner ?? 'url';
            setTimeout(() => {
                this.getPayRunDetails()
            }, 100)
        }, (err: any) => {
            this.spinnerService.toasterError(err);
            this.spinnerService.raiseDataEmitterEvent('off')
        }
        )
    }
    public PayrollRun() {
        this.spinnerService.raiseDataEmitterEvent('on');
        const pay_run_details = this.selectedFiltedPayrolls.map((payroll: any) => ({
            employee_id: payroll.employee_id,
            company_id: payroll.company_id,
            client_id: payroll.client_id,
            financial_month_year: payroll.financial_month_year,
        }));
        this.spinnerService.raiseDataEmitterEvent('on')
        const params: any = {};
        params.client_id = this.ClientID;
        params.payroll_runner = this.payrollRunner;
        params.pay_run_details = pay_run_details;
        this.apiModuleService.list('admin/payroll-admin/payroll/run', params).subscribe((data) => {
            this.selectedPayrolls = [];
            this.selectedFiltedPayrolls = [];
            ($('#payrollruncheck') as any).modal('hide');
            this.getPayRunDetails()

           
        }, (err: any) => {
            this.spinnerService.raiseDataEmitterEvent('off');

            this.spinnerService.toasterError(err);
        }
        )
    }
    refreshDataFromQueue() {
        this.spinnerService.raiseDataEmitterEvent('on');
        if(this.payrollRunner!='queue'){
            this.apiModuleService.get("sque_output").subscribe((data) => {
                setTimeout(() => {
                    this.getPayRunDetails()
                }, 100)
            }, (err: any) => {
                  this.toastrService.error(err, 'TryAgain');
                  this.spinnerService.raiseDataEmitterEvent('off');

            });
           
        }else{
            this.getPayRunDetails()
        }
     
    }

    clear(table: Table) {
        table.clear();
      }
    public DownloadFile(): void {

        this.spinnerService.raiseDataEmitterEvent('on');
        const params: any = {};
        params.client_id = this.ClientID;
        params.user_key = this.userKey;
        params.company_id = this.CompanyID;
        params.pay_group_id = this.PaygroupID;
        params.financial_month_year = this.financial_month_year;
        this.apiModuleService.list("admin/payroll-admin/payroll/download", params).subscribe((data) => {
            const docDetails = data.data.payroll;
            this.documentService.DownloadExcel(docDetails.name, docDetails.excel);
            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err: any) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            // let errors = err.error.data.payroll;
            // errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            // this.toastrService.error(errors, 'TryAgain');
        });

    }
}
